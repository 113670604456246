@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~antd/dist/antd.css';
@import './assets/Css/App.scss';
@import './assets/Css/Misc.scss';
@import '~react-toastify/dist/ReactToastify.css';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0 !important;
  font-family: 'Inter', sans-serif !important;
  background: var(--background-dark);
  height: 100%;
  padding: 0 !important;
  box-sizing: border-box !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter';
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

#numberContainer {
  overflow: hidden;
  position: relative;
}

iframe {
  display: none;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slide-animation {
  animation: slide 5s linear infinite;
}

.green-gradient-circle-bottom-right {
  position: absolute;
  width: 60%;
  height: 20%;
  background: linear-gradient(180deg, rgba(24, 255, 158, 0) 0%, rgba(0, 255, 240, 0.6) 100%) !important;
  filter: blur(218.25277709960938px);
  bottom: 0;
  flex-shrink: 0;
  z-index: -1;
  right: 0;
}

.green-gradient-circle-bottom-right-mobile {
  position: absolute;
  width: 60%;
  height: 20%;
  background: linear-gradient(180deg, rgba(24, 255, 158, 0) 0%, rgba(0, 255, 240, 0.8) 100%);
  filter: blur(98.31880187988281px);
  bottom: 1rem;
  flex-shrink: 0;
  z-index: -1;
  right: 0;
}

.green-gradient-circle-top-left {
  position: absolute;
  width: 40%;
  height: 20%;
  background: linear-gradient(
    180deg,
    rgba(109, 141, 255, 0.5) 0%,
    rgba(105, 234, 234, 0.35) 56.62%,
    rgba(96, 249, 194, 0.06) 91.36%
  );
  filter: blur(180.19605255126953px);
  transform: rotate(-95.113deg);
  flex-shrink: 0;
  z-index: -1;
  top: 0;
  left: 0;
}

.green-gradient-circle-top-left-mobile {
  position: absolute;
  width: 40%;
  height: 20%;
  background: linear-gradient(
    136deg,
    rgba(109, 141, 255, 0.5) 0%,
    rgba(105, 234, 234, 0.35) 56.62%,
    rgba(96, 249, 194, 0.06) 91.36%
  ) !important;
  filter: blur(46.48789596557617px);
  transform: rotate(-95.113deg);
  flex-shrink: 0;
  z-index: -1;
  top: 6rem;
  left: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #9cafca;
  border-radius: 8px;
  width: 2px;
  height: 2px;
}

input,
input:focus,
button:focus-visible {
  outline: none !important;
  box-shadow: none !important;
  border-color: none !important;
}

.walletInfoGradient {
  background: linear-gradient(93deg, #4873a7 2.27%, #4b7b9c 39.36%, #5a97a2 69.04%, #69c0b6 104.02%), #1d2536;
}

@keyframes spin-reverse {
  to {
    transform: rotate(-360deg);
  }
}

.animate-spin-reverse {
  animation: spin-reverse 1s linear infinite;
}
.half-width {
  width: 50%;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.grow-down-animation {
  animation: growDown 400ms ease-in-out forwards;
  transition: transform 300ms ease-in-out;
}

@keyframes growRightToLeft {
  0% {
    transform: scaleX(0);
    transform-origin: top right;
  }
  80% {
    transform: scaleX(1.1);
    transform-origin: top right;
  }
  100% {
    transform: scaleX(1);
    transform-origin: top right;
  }
}

.grow-right-to-left-animation {
  animation: growRightToLeft 500ms ease-in-out forwards;
  transition: transform 300ms ease-in-out;
}

.modal-open {
  transition: transform 300ms ease-in-out;
  animation: zoom 400ms ease-in-out forwards;
}

@keyframes zoom {
  0% {
    transform: scaleX(0);
    transform-origin: center;
  }
  80% {
    transform: scaleX(1.1);
    transform-origin: center;
  }
  100% {
    transform: scaleX(1);
    transform-origin: center;
  }
}

@keyframes growLeftToRight {
  0% {
    transform: scaleX(0);
    transform-origin: top left;
  }
  80% {
    transform: scaleX(1.1);
    transform-origin: top left;
  }
  100% {
    transform: scaleX(1);
    transform-origin: top left;
  }
}

.grow-left-to-right-animation {
  animation: growLeftToRight 400ms ease-in-out forwards;
  transition: transform 300ms ease-in-out;
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

.modalFadeIn {
  animation: fade-in 300ms forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loader-custom {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 6px;
  border-radius: 50%;
  -webkit-animation: dot-pulse2 1.5s ease-in-out infinite;
  animation: dot-pulse2 1.5s ease-in-out infinite;
}

.dot-1 {
  background-color: #44b496;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.dot-2 {
  background-color: #a7fff3;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.dot-3 {
  background-color: #44b496;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.dot-4 {
  background-color: #a7fff3;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.dot-5 {
  background-color: #44b496;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@keyframes dot-pulse2 {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }
}

.down-arrow-custom {
  position: absolute;
  top: calc(100vh - 60px);
  left: calc(50% - 14px);
  width: 0;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
  animation: jumpInfinite 1.5s infinite;
}

.arrow-5 {
  width: 13px;
  height: 8.5px;
  display: grid;
  overflow: hidden;
}

.arrow-5:before,
.arrow-5:after {
  content: '';
  grid-area: 1/1;
  background: currentColor;
  clip-path: polygon(
    0 4px,
    calc(100% - 2.5px) 4px,
    calc(100% - 4.5px) 0,
    100% 50%,
    calc(100% - 4.5px) 100%,
    calc(100% - 2.5px) calc(100% - 4px),
    0 calc(100% - 4px)
  );
  animation: a5 1s infinite;
  transform: translate(calc(0% + var(--s, 0%)));
}

.arrow-5:after {
  --s: -100%;
}

@keyframes a5 {
  80%,
  100% {
    transform: translate(calc(100% + var(--s, 0%)));
  }
}

.arrow-4 {
  width: 7px;
  height: 6px;
  display: grid;
}
.arrow-4:before,
.arrow-4:after {
  content: '';
  grid-area: 1/1;
  background: #7dccb7;
  clip-path: polygon(
    0 2px,
    calc(100% - 3px) 2px,
    calc(100% - 3px) 0,
    100% 50%,
    calc(100% - 3px) 100%,
    calc(100% - 3px) calc(100% - 2px),
    0 calc(100% - 2px)
  );
  animation: a4 0.5s infinite alternate;
}

.arrow-4:after {
  --s: -1;
}
@keyframes a4 {
  0% {
    transform: scale(var(--s, 1)) translate(5px, -2px) translate(2px);
  }
  100% {
    transform: scale(var(--s, 1)) translate(5px, -2px) translate(-2px);
  }
}

.border-custom {
  background: linear-gradient(270deg, #98a6c0 0%, rgba(152, 166, 192, 0) 101.72%);
  height: 1.095px;
}
.border-custom2 {
  background: linear-gradient(18deg, #98a6c0 0%, rgba(152, 166, 192, 0) 101.72%);
  height: 1.095px;
}

.loader-spinner-custom {
  width: 107px;
  height: 107px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 7px solid;
  border-color: #0cbc8b #0cbc8b transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-spinner-custom::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 6px solid;
  border-color: transparent #a7fff3 #a7fff3;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.custom-placeholder-color::placeholder {
  color: #e8f0fc !important;
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #20263a;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
    animation: shimmer 15s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.blog-post {
  &__headline {
    font-size: 1.25em;
    font-weight: bold;
  }

  &__meta {
    font-size: 0.85em;
    color: #6b6b6b;
  }
}

.o-media {
  display: flex;

  &__body {
    flex-grow: 1;
    margin-left: 1em;
  }
}

.o-vertical-spacing {
  > * + * {
    margin-top: 0.75em;
  }

  &--l {
    > * + * {
      margin-top: 2em;
    }
  }
}

.ant-tooltip-arrow-content[style*='--antd-arrow-background-color'] {
  --antd-arrow-background-color: #7a87a6 !important;
}

.dark .ant-tooltip-arrow-content[style*='--antd-arrow-background-color'] {
  --antd-arrow-background-color: #0a0b18 !important;
}

.dark .ant-tooltip-inner {
  background-color: #0a0b18 !important;
}

.ant-tooltip-inner {
  background-color: #7a87a6 !important;
}

.accordion {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;
}

.accordion.open {
  max-height: 9rem;
}
