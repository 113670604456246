.tooltip_info {
  color: #121619 !important;
}

.tooltip_info_title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #121619;
}

.tooltip_info_desc {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.8px;
}

.tooltip_common:hover {
  background: transparent !important;
}

.tooltip_common_stable {
  object-fit: contain;
  height: 13.33px !important;
  width: 13.33px !important;
}

.green-radient {
  background: linear-gradient(89.44deg, #017f52 13.87%, #00ec97 100%);
}

.green-radient:hover {
  background: #009962;
}

.green-radient:focus {
  background: #00ec97;
}

.setting-screen {
  background: #1a2130;
  border: 1px solid hsla(0, 0%, 100%, 0.15);
  border-radius: 20px;
  overflow: hidden;
}

.atm-chain-container {
  width: 100%;
  background: rgba(28, 34, 53, 0.7);
}

.atm-chain-info {
  width: 84px;
  height: 68px;
  border-radius: 6px;
  background: #262c3f;
}

.atm-chain-info-active {
  border: 1px solid #415db9;
  background: #20263a;
  width: 84px;
  height: 68px;
  border-radius: 6px;
  background: #262c3f;
}
