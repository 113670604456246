.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding-right: 16px !important;
}

.ant-collapse {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) -135.28%,
    rgba(255, 255, 255, 0.04) 106.83%,
    rgba(255, 255, 255, 0.08) 144.75%
  ) !important;
  border-radius: 4px !important;
  border: none !important;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  background: linear-gradient(
    100.23deg,
    #fcf95c 0%,
    #31d08f 12%,
    #2de370 39%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
}

.ant-collapse > .ant-collapse-item {
  border: 0 !important;
}

.ant-collapse
  > .ant-collapse-item.ant-collapse-no-arrow
  > .ant-collapse-header {
  padding: 12px 24px !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 24px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center !important;
}

.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85) !important;
  background-color: transparent !important;
  border-top: none !important;
}

@media screen and (max-width: 768px) {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 8px !important;
  }
}

@media (max-width: 767px) {
  .ant-modal {
    width: 100% !important;
  }
}

.ant-modal-mask {
  backdrop-filter: blur(10px);
}

.dark .ant-modal-content {
  border-radius: 12px;
  background-color: #141824;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.ant-modal-content {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.ant-modal-body {
  padding: 0 !important;
}

.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

// .ant-input {
//     background-color: #353643;
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 500;
//     line-height: 28px;
//     color: #F3F5F7;
// }

.ant-select-dropdown {
  background-color: #000000 !important;
  padding: 0 !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  display: flex !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #2e2e30 !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #2e2e30 !important;
}

.ant-select-item {
  background: #242736 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.31) !important;
  height: 38px !important;
  padding-top: 8px !important;
  color: white !important;
  display: flex;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #242736 !important;
  padding-top: 5px !important;
  border: 1px solid #3c3d4a;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #00ffa4 !important;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #4d5358 !important;
  border: 4px;
}

.ant-tooltip-inner {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  background-color: rgba(50, 56, 70, 1) !important;
  color: #fff !important;
  border-radius: 8px;
  margin-bottom: -11px;
}
