.reward-page-background-top {
  background: linear-gradient(180deg, #161a21 0%, #161a21 100%);
  filter: blur(100px);
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
}
.reward-page-background-bottom {
  background: linear-gradient(180deg, #142c2e 0%, #161a21 100%);
  filter: blur(100px);
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.gredient-background {
  background: linear-gradient(180deg, rgba(24, 255, 158, 0) 0%, #17ffe3 100%);
  filter: blur(100px);
  width: 100px;
  height: 100px;
  position: absolute;
}

.referral-container {
  display: flex;
  align-items: start;
  flex-direction: column;
  border-radius: 0.5rem;
  width: 100%;
  gap: 1.25rem;
  .referral-heading {
    color: var(--Exception-white, var(--white, #fff));
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 138.462% */
  }
  .referral-container-heading {
    color: #c7deff;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 138.462% */
    margin-bottom: 0.5rem;
  }
  .referral-container-sub-heading {
    color: #9fb2cd;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 22.5px */
    letter-spacing: 0.208px;
  }
}

.referral-container-box {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 2rem;
  position: relative;
}

.referral-container-box-left {
  display: flex;
  flex-direction: column;
  padding: 2.25rem;
  align-items: start;
  justify-content: space-between;
  background-color: #141824;
  width: 100%;
  max-height: 200px;
  position: relative;
  border-radius: 8px;
  .reffered-btn {
    border-radius: 100px;
    background: linear-gradient(90deg, #94e3d5 0%, #aee18e 100%);
    padding: 10px 20px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: 0.208px;
    text-transform: capitalize;
  }
  .refer-id {
    color: #c7deff;

    /* text-base/leading-6/text-medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
  img {
    height: 45px;
    width: 45px;
  }
}

.referral-container-box-right {
  width: 20%;
  height: 100%;
  max-height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: rgba(187, 184, 184, 0.04);
  border: 1px solid #fff;
  border-radius: 10px;
  min-width: 300px;
  .points-btn {
    border-radius: 120px;
    border: 0.604px solid #48c229;
    background: linear-gradient(90deg, rgba(32, 122, 0, 0.2) 0%, rgba(86, 247, 238, 0.2) 100%);
    padding: 0.5rem 1rem;
  }
  .points-head {
    color: #8dffe4;
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 33px */
  }
  .navigate-btn {
    border-radius: 120px;
    padding: 0.5rem 1rem;
  }
  .task-activity-container {
    border-radius: 10px;
    border: 1px solid #fff;
    background: rgba(187, 184, 184, 0.04);
  }
  .total-earning-container {
    border-radius: 10px;
    border: 1px solid #fff;
    background: rgba(187, 184, 184, 0.04);
  }
}

.referral-container-box-right-heading {
  color: #c7deff;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 127.273% */
}

.referral-action-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  margin-top: 2.25rem;
  justify-content: space-between;
  .referral-container-code-input {
    border-radius: 8px;
    background: #23293b;
    flex: 1;
    height: 100%;
    padding: 0.625rem 1.25rem;
  }
  .referral-container-copy-link-btn {
    color: #000238;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
}

.referral-container-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 1300px) {
  .referral-container-box-left {
    .refer-id {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 330px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .referral-container-box-left {
    padding: 1.25rem;
    .refer-id {
      max-width: 200px;
    }
    img {
      width: 35px;
      height: 35px;
    }
    .reffered-btn {
      font-size: 12px;
    }
  }
  .referral-container-box-right {
    max-height: 189px;
  }
  .referral-container-heading {
    font-size: 24px;
    line-height: 33px;
    .referral-container-sub-heading {
      font-size: 14px;
    }
  }
  .referral-action-container {
    margin-top: 1.25rem;
    .referral-container-code-input {
      padding: 0.5rem 1rem;
    }
    .referral-container-copy-link-btn {
      font-size: 16px;
      line-height: 24px;
      padding: 8px 32px;
    }
  }
}

@media screen and (max-width: 768px) {
  .referral-container-box-right {
    max-height: 200px;
    height: 200px;
    width: 100%;
  }
  .referral-points-container {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .referral-points-container {
    width: 100%;
  }
  .referral-container {
    .referral-heading {
      font-size: 1rem;
      line-height: 138.462%;
    }
    .referral-container-heading {
      font-size: 1rem;
      line-height: 150%;
    }
    .referral-container-sub-heading {
      font-size: 0.75rem;
      line-height: 140%;
    }
  }
  .referral-action-container {
    .referral-container-code-input {
      font-size: 0.75rem;
      line-height: 200%;
    }
    .referral-container-copy-link-btn {
      font-size: 0.875rem;
      line-height: 171%;
      padding: 4px 32px;
    }
  }
  .referral-container-box-left {
    .refer-id {
      font-size: 0.75rem;
      line-height: 200%;
      max-width: 100px;
    }
    .reffered-btn {
      padding: 5px 10px;
    }
  }
}

@media screen and (max-width: 400px) {
  .referral-action-container {
    .referral-container-code-input {
      width: 50%;
    }
  }
  .referral-container-box-left {
    .refer-id {
      width: 100%;
      max-width: 80px;
    }
    .referral-action-avatar-container {
      gap: 0.5rem;
    }
  }
}
