:root {
  --theme-neon-bg: #00ffa4;
  background: #171a23;

  --white: #ffffff;
  --background-disabled: rgba(255, 255, 255, 0.05);
  --font-disabled: rgba(255, 255, 255, 0.6);
  --font-text: #878d96;
  --font-light: #939394;
  --font-mono: rgba(255, 255, 255, 0.4);
  --font-secondary: #b6b6b6;
  --background-rail: #00ffa4;
  --background-input: #151517;
  --border-input: rgba(255, 255, 255, 0.2);
  --slider-rail: #4d5358;
  --font-light-white: #f3f5f7;
  --black: #000000;
  --border-light-black: rgba(255, 255, 255, 0.1);
  --close-bg: #1a1e22;
  --font-intro: #dde1e6;
  --modal-bg: rgba(255, 255, 255, 0.04);
  --modal-shadow: rgba(0, 0, 0, 0.37);
  --modal-btn: #272d31;
  --modal-error: #eb6464;
  --summary_text: #7e7e86;
  --form-text: rgba(255, 255, 255, 0.54);
  --form-title: rgba(255, 255, 255, 0.24);
  --btn-border-disabled: rgba(243, 245, 247, 0.24);
  --green-disabled: #009962;
  --green-active: #00cc83;
  --font-primary: #00ffa4;
  --font-secondary: #ffffff;
  --font-tertiary: rgba(255, 255, 255, 0.54);
  --font-four: #878d96;
  --font-five: #f3f5f7;
  --font-six: #00cc83;
  --font-seven: rgba(255, 255, 255, 0.6);
  --font-eight: rgba(255, 255, 255, 0.87);
  --font-nine: rgba(255, 255, 255, 0.38);
  --background-primary: #1a1e22;
  --background-secondary: #1a1e22;
  --background-tertiary: #272d31;
  --background-four: rgba(255, 255, 255, 0.1);
  --background-five: #4d5358;
  --border-primary: #2c2d3a;
  --border-secondary: #272d31;
  --border-tertiary: rgba(255, 255, 255, 0.16);
  --border-four: #00cc83;

  --onboard-connect-sidebar-background: #1a1b1f;
  --onboard-connect-sidebar-color: #fff;
  --onboard-connect-sidebar-progress-background: #fff;
  --onboard-connect-sidebar-border-color: #0d0d0d;
  --onboard-connect-sidebar-progress-color: green;
  --onboard-connect-header-color: #fff;
  --onboard-main-scroll-container-background: #1a1b1f;
  --onboard-close-button-color: #fff;
  --onboard-wallet-button-background: #343a3f;
  --onboard-wallet-button-background-hover: #697077;
  --onboard-wallet-button-border-color: #0d0d0d;
  --border-color: #0d0d0d;
}

.header.svelte-1qwmck3 {
  border-color: #0d0d0d !important;
}

.max-btn {
  color: #7dccb7;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.approved-txt-box {
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  height: 17px;
  background: rgba(88, 162, 248, 0.18);
  border-radius: 4px;

  font-weight: 700;
  font-size: 8px;
  text-align: center;
  letter-spacing: 0.683333px;
  text-transform: uppercase;
  color: #58a2f8;
}

.disabled_add_token_add_button {
  color: #4d5358 !important;
  border: 2px solid #4d5358 !important;
}

.disabled_add_token_half_border {
  background: #4d5358 !important;
}

.add_tokens_search {
  top: 13px;
  left: 13px;
  position: absolute;
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.add_tokens_main_search {
  height: 42px;
  width: 100%;
  color: #878d96;
  background: #272d31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 0 50px;
  display: flex;
  align-items: center;
  font-feature-settings:
    'salt' on,
    'liga' off;
}

.add_token_btn {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  column-gap: 11px;
  text-transform: uppercase;
  color: var(--white);
  background: var(--modal-btn);
  border: 1px solid var(--font-text);
  border-radius: 2px;
  width: 146px;
  height: 40px;
}

.add_token_btn img {
  width: 14px;
  height: 14px;
}

.add_tokens_selected {
  height: 54px;
  background: var(--white);
  border-radius: 3px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #232325;
}

.add_tokens_unselected {
  height: 54px;
  background: var(--background-disabled);
  border-radius: 3px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(255, 255, 255, 0.87);
}

.add_token_btn:active {
  background: rgba(243, 245, 247, 0.05);
}

.add_token_btn:hover {
  border: 1px solid var(--font-light-white);
  color: rgba(255, 255, 255, 0.87);
}

.add_token_btn:focus {
  background: var(--btn-border-disabled);
  border: 1px solid var(--btn-border-disabled);
}

.add_token_btn:disabled {
  background: rgba(243, 245, 247, 0.02);
  color: var(--btn-border-disabled);
  border: 1px solid var(--btn-border-disabled);
}

.add_token_modal_close:hover {
  background: #272d31;
}

.add_tokens_gap {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.add_tokens_main {
  width: 578px;
  overflow: scroll;
  padding: 32px 0 48px;
  background: #1a1e22;
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 2px;
}

.add_tokens_main_row_cont_child:hover .add_tokens_main_row_cont_child_add {
  color: #2de370;
  border: 2px solid #2de370;
}

.selected_token_add_token_modal {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 44px;
  border-radius: 2px;
}

.disabled_token_add_token_modal {
  background: #1a1e22;
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 2px;
  height: 44px;
  border-radius: 2px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4d5358;
}

.add_tokens_modal_head_text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
  color: #ced4d9;
}

.add_tokens_main_row_cont_child:hover .grey_white {
  background: #2de370;
}

.add_tokens_main_row_cont {
  max-height: 548px;
  overflow-y: scroll;
}

.add_tokens_main_row_cont_child {
  height: 44px;
  background: #1a1e22;
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 2px;
}

.add_tokens_main_row_cont_head {
  margin-bottom: 11px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 82px;
  color: rgba(255, 255, 255, 0.54);
}

.add_tokens_main_box {
  cursor: pointer;
  height: 40px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid var(--border-light-black);
  border-radius: 2px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--form-text);
}

.add_tokens_main_box_hover {
  color: #000000;
  background: #f3f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  padding-bottom: 1px;
}

.add_tokens_main_box:hover .add_tokens_main_box_hover {
  background: #f36969;
}

.add_token_swapped_token {
  font-family: 'Inter';
  width: 97px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  text-align: center;
  color: #f3f5f7;
  mix-blend-mode: normal;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.add_token_active {
  border: 1px solid var(--theme-neon-bg);
}

.add_token_disabled {
  border: 1px solid gray;
  background: darkgray;
  opacity: 0.9;
}

.add_token_header_name {
  width: 172px;
}

.add_tokens_main_row_cont_child_name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 176px;
  text-align: left;
  color: rgba(255, 255, 255, 0.54);
}

.add_tokens_main_row_cont_child_value_live {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 161px;
  text-align: left;
  color: rgba(255, 255, 255, 0.54);
}

.add_tokens_main_row_cont_child_value {
  font-family: 'Inter';
  font-style: normal;
  width: 104px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.54);
}

.add_tokens_main_row_cont_child_add {
  height: 24px;
  margin-left: 27px;
  margin-right: 29px;
  width: 24px;
  border-radius: 100px;
  background: #1a1e22;
  border: 2px solid #f3f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f3f5f7;
}

.add_tokens_main_row_cont_child_add_img {
  width: 16px;
  height: 16px;
}

.add_tokens_main_row_cont_child_remove {
  color: #f36969 !important;
  border: 2px solid #f36969;
}

.selected_token_add_token_modal:hover .add_tokens_main_row_cont_child_remove {
  background: #f36969 !important;
  color: white !important;
}

.submit-btn {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #44b496;
  border-radius: 6px;
  height: 48px;
  text-transform: uppercase;
}

.submit-btn:hover {
  background: #5bcbad;
}

.submit-btn:disabled {
  background: #738480 !important;
  color: #82a498 !important;
}

.btn-setting-tab {
  background: #303d53;
  border-radius: 6px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  font-size: 14px;
  text-align: center;
  color: #cbcbcb;
  width: 70px;
  height: 52px;
}

.btn-setting-tab-active {
  background: #637596;
  border-radius: 6px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  color: #ffffff;
  width: 70px;
  height: 52px;
}

.token_info{
  animation: slideInFromTop 0.3s ease-out forwards;
}

.atm_row:hover .token_info {
  display: none;
  box-sizing: border-box;
}

.token_explorer {
  display: none;
}

.atm_row:hover .token_explorer {
  display: flex;
  box-sizing: border-box;
  animation: slideInFromBottom 0.3s ease-out forwards;
}

@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-75%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-box {
  border-radius: 6px;
  border: 1px solid #37405b;
  background: #20263a;
  color: #94abcc;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.search-box::placeholder {
  color: #94abcc;
}

.approval-token-box {
  border-radius: 10px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.approval-btn {
  border-radius: 100px;
  width: 89px;
  height: 26px;
  background: #44b496;
  color: #000238;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.container {
  background: #141824;
  border-radius: 12px;
  overflow: hidden;
}
