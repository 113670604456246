.chain-list-container p {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.chain-list-container .info {
  color: #c7deff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.chain-details {
  border-radius: 10px;
  background: #1a1f30;
  height: 61px;
}

.txt-available-on {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.chain-info {
  border-radius: 86.831px;
  height: 34px;
  background: #2a1e48;
  color: var(--neutral-gray-50, #f3f5f7);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
